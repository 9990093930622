.button-icon {
    display: inline-grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 9px;
    align-items: center;
  
    height: 50px;
    width: max-content;
    padding: 0 18px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    border-radius: 10px;
    border: 1px solid transparent;
  }
  .button-icon.small {
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    line-height: 26px;
}
  .button-icon svg {
    vertical-align: bottom;
  }
  .button-icon.black {
    background: #000000;
    color: #ffffff;
  }
  .button-icon.black:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .button-icon.purple {
    color: #ffffff;
    background-color: rgba(145, 48, 222, 1);
  }
  .button-icon.purple:hover {
    background-color: rgba(145, 48, 222, 0.8);
  }
  .button-icon.blue {
    background: #2C3FEE;
    color: #ffffff;
  }
  .button-icon.blue:hover {
    background: rgba(44, 63, 238, 0.8);
  }
  .button-icon.neutral {
    background: #F2F2F2;
    color: #000000;
  }
  .button-icon.neutral:hover {
    background: #E5E5E5;
  }
  .button-icon.light-blue {
    background: rgba(56, 120, 234, 1);
    color: #ffffff;
  }
  .button-icon.light-blue:hover {
    background: rgba(56, 120, 234, 0.8);
  }
  .button-icon.outline {
    background-color: transparent;
  }
  
  .button-icon.outline.neutral {
    border-color: #F2F2F2;
  }
  .button-icon.outline.neutral:hover {
    background-color: transparent;
    border-color: #CBCBCB;
  }
  .button-icon.outline.black {
    border-color: #000000;
    color: #000000;
  }
  .button-icon.outline.black:hover {
    border-color: #CBCBCB;
    color: #000000;
    background-color: transparent;
  }