.Login {
  margin-top: 40px;
  margin-bottom: 50px;
}


.Login__title {
  margin-bottom: 40px;
}

.Login__form {
  max-width: 320px;
}

.Login__form .ant-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Login__form .ant-form-item .ant-col {
  width: 100%;
  text-align: left;
}

.Login .ant-input-affix-wrapper {
  border-radius: 10px;
  padding: 0 10px 0 0px;
}

.Login .ant-form-item-control-input input {
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
}

.Login__form .ant-btn {
  background-color: #000;
  border-color: #000;
  border-radius: 10px;
  padding: 12px 16px;
  height: auto;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.Login__form .ant-btn:hover {
  border-color: #333;
  background-color: #333;
}
