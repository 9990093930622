.dashboard {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 30px 40px;
  max-width: 1610px;
  margin: auto;
}

.dashboard__sidebar {
  width: 100%;
}

.dashboard__body {
  flex: 1;
  margin-top: 50px;
}

.dashboard__heading {
  font-size: 42px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: -2px;
  margin-bottom: 42px;
}

.dashboard__subheading {
  margin-bottom: 35px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.dashboard__subheading-item {
  margin-right: 0;
  margin-bottom: 30px;
  width: 100%;
}

.dashboard__subheading-item:last-child {
  margin-right: 0;
}

.dashboard__title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}

@media (min-width: 376px) {
  .dashboard__subheading-item {
    width: auto;
  }
}

@media (min-width: 680px) {
  .dashboard {
    padding: 75px 55px 91px;
  }

  .dashboard__sidebar {
    width: 30%;
    max-width: 253px;
  }

  .dashboard__body {
    margin-left: 30px;
    margin-top: 0;
  }

  .dashboard__subheading-item {
    margin-right: 60px;
  }
}
