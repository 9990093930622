.statistic-card {
  background-color: #f8f8f8;
  border-radius: 10px;
  color: #000;
  padding: 15px 20px 23px;
}

.statistic-card * {
  margin: 0;
  padding: 0;
}

.statistic-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.statistic-card__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.statistic-card__name {
  font-size: 20px;
  line-height: 25px;
  color: #7c7c7c;
}

.statistic-card__diff {
  font-size: 12px;
  line-height: 10px;
}

.statistic-card__diff_null {
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  color: #a9a9a9;
}

.statistic-card__diff_plus {
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  color: #22cd52;
}

.statistic-card__diff_minus {
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  color: #fe3434;
}

.statistic-card__value {
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -1px;
}

.statistic-card__period {
  font-size: 12px;
  line-height: 10px;
  font-weight: 600;
  color: #a9a9a9;
}

.statistic-card__period span:first-child {
  display: inline-block;
  margin-bottom: 6px;
}

.statistic-card__svg {
  margin-right: 4px;
}
