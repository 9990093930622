.header .wrapper {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #f2f2f2;
}

.header__username {
  margin-right: 10px;
  color: #000;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 576px) {
  .header .wrapper  {
    padding: 10px 15px;
  }
}
