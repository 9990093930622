.calendar .text {
  margin-right: 15px;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.calendar {
  display: inline-flex;
  max-width: 380px;
}

.calendar__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
}

.calendar__field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.calendar__label {
  margin-right: 15px;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  min-width: 38px;
}

.calendar .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 350px;
}

.calendar .item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 400px) {
  .calendar .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
}

@media (min-width: 460px) {
  .calendar__wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .calendar__label {
    min-width: auto;
  }

  .calendar__field {
    margin-right: 15px;
  }
}
