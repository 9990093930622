.header-panel .column {
    display: flex;
    align-items: center;
}
.header-panel .right-column {
    justify-content: flex-end;
}

@media (max-width: 768px) {
  .header-panel .right-column {
      margin-top: 30px;
      justify-content: flex-start;
  }
}