.view-plan .statistic-row .item {
    margin-bottom: 30px;
}

.view-plan .statistic-row .item:nth-last-of-type(1) {
    margin-bottom: 0;
}

.view-plan .button-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
}
.view-plan .statistic-row .right-column {
    padding-left: 70px;
}
.view-plan .table-row .right-column {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.view-plan .table-row .ant-table-tbody > tr > td,
.view-plan .table-row .ant-table-thead > tr > th {
    border-bottom: none;
}

.view-plan .plan-name {
    margin-top: 70px;
}

.view-plan .plan-description {
    margin-top: 5px;
}

@media (max-width: 1270px) {
    .view-plan .button-wrapper {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 991px) {
    .view-plan .table-row .right-column {
        justify-content: flex-start;
    }
    .view-plan .button-wrapper {
        grid-template-columns: auto auto;
    }
    .view-plan .statistic-row .right-column {
        padding-left: 15px;
    }
}

@media (max-width: 375px) {
    .view-plan .button-wrapper {
        grid-template-columns: 1fr;
    }
}
