.packs-header .right-column {
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .packs-header .right-column {
    margin-top: 30px;

    justify-content: flex-start;
  }
}
