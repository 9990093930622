.nav-sidebar {
  list-style: none;
  font-size: 20px;
  line-height: 25px;
  color: #000;
  padding-left: 0;
  display: inline-flex;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: 0;
  font-weight: 500;
}

.nav-sidebar__item {
  margin-right: 15px;
  margin-bottom: 20px;
}

.nav-sidebar__item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.nav-sidebar__link,
.nav-sidebar__link:visited,
.nav-sidebar__link:active {
  color: #000;
  transition: color 0.15s ease-in;
}

.nav-sidebar__link:hover {
  text-decoration: none;
  color: #A9A9A9;
}

.nav-sidebar__link_active,
.nav-sidebar__link_active:visited,
.nav-sidebar__link_active:active {
  color: #A9A9A9;
}

.nav-sidebar__delimiter {
  width: 100%;
  height: 1px;
  background-color: #E7E7E7;
  margin-bottom: 20px;
  display: none;
}

@media (min-width: 768px) {
  .nav-sidebar {
    flex-direction: column;
  }

  .nav-sidebar__item {
    margin-right: 0;
  }

  .nav-sidebar__delimiter {
    display: block;
  }
}
