.view-pack .button-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
}
.view-pack .statistic-row .right-column {
  padding-left: 70px;
}
.view-pack .table-row .right-column {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.view-pack .table-row .ant-table-tbody > tr > td,
.view-pack .table-row .ant-table-thead > tr > th {
  border-bottom: none;
}

.view-pack .table-row .ant-table-container table > thead > tr:first-child th:first-child,
.view-pack .table-row .ant-table-tbody .ant-table-cell:first-child {
  padding-left: 0;
}

@media (max-width: 1270px) {
  .view-pack .button-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .view-pack .table-row .right-column {
    justify-content: flex-start;
  }
  .view-pack .button-wrapper {
    grid-template-columns: auto auto;
  }
  .view-pack .statistic-row .right-column {
    padding-left: 15px;
  }
}

@media (max-width: 375px) {
  .view-pack .button-wrapper {
    grid-template-columns: 1fr;
  }
}
