/*site-footer*/
.site-footer {
  padding-top: 55px;
  padding-bottom: 30px;
}
.site-footer.bordered {
  margin-top: 100px;
  padding-top: 65px;
  border-top: 1px solid #F2F2F2;
}
.site-footer .wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 30px;
}
.site-footer .footer-text {
  padding-left: 50px;
  padding-right: 40px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.376364px;
  color: #A9A9A9;
}
.site-footer .second,
.site-footer .third,
.site-footer .fourth {
  padding-top: 10px;
}
.site-footer .first {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-column: 1/4;
}
.site-footer .second {
  -ms-grid-column: 7;
  -ms-grid-column-span: 2;
  grid-column: 7/9;
}
.site-footer .third {
  -ms-grid-column: 9;
  -ms-grid-column-span: 2;
  grid-column: 9/11;
}
.site-footer .fourth {
  grid-column: 11/-1;
}
.site-footer .list {
  padding-top: 13px;
}
.site-footer  ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-footer .header {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.376364px;
  color: #A9A9A9;
  overflow: hidden;
}
.site-footer .link {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;

  color: #000000;
  text-decoration: none;
  text-align: left;
  mix-blend-mode: normal;
  overflow: hidden;

  padding: 0;
  border: none;
  background-color: transparent;
}
.site-footer .link:hover {
  color: rgba(0, 0, 0, 0.6);
}
.site-footer .list .link {
  display: block;
  margin-bottom: 20px;
}

.site-footer .fifth {
  padding-top: 70px;
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-footer .bottom-text {
  font-family: "DM Sans", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /*line-height: 35px;*/
  letter-spacing: -0.4px;
  color: #A9A9A9;
  min-width: 360px;
  outline: 2px solid transparent;
  overflow: hidden;

}
.site-footer .socials {
  display: flex;
}
.site-footer .bottom-link {
  padding: 0 15px;
}
.site-footer .bottom-link:hover {
  color: #000000;
}
.site-footer .right {
  padding-right: 30px;
}

@media screen and (max-width: 1199px) {
  .site-footer .footer-text {
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .site-footer .wrapper {
    -ms-grid-rows: auto 30px auto;
    grid-template-rows: auto auto;
  }
  .site-footer .first {
    grid-column: 1/-1;
    text-align: center;
  }
  .site-footer .footer-text {
    padding: 0;
    padding-top: 15px;
  }
  .site-footer .second {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3/5;
  }
  .site-footer .third {
    -ms-grid-column: 6;
    -ms-grid-column-span: 2;
    grid-column: 6/8;
  }
  .site-footer .fourth {
    grid-column: 9/-1;
  }
  .site-footer .fifth {
    flex-direction: column;
  }
  .site-footer .left .bottom-text {
    text-align: center;
  }
  .site-footer .right {
    padding-right: 0;
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .site-footer .second {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1/4;
  }
  .site-footer .third {
    -ms-grid-column: 5;
    -ms-grid-column-span: 2;
    grid-column: 5/7;
  }
  .site-footer .fourth {
    grid-column: 9/-1;
  }
}

@media (max-width: 576px) {
  .site-footer .list li {
    display: flex;
    justify-content: center;
  }
  .site-footer .wrapper {
    -ms-grid-rows: auto 30px auto 30px auto 30px auto;
    grid-template-rows: auto auto auto auto;
  }
  .site-footer .second {
    grid-column: 1/-1;
    -ms-grid-row: 2;
    grid-row: 2;
    text-align: center;
  }
  .site-footer .third {
    grid-column: 1/-1;
    -ms-grid-row: 3;
    grid-row: 3;
    text-align: center;
  }
  .site-footer .fourth {
    grid-column: 1/-1;
    text-align: center;
  }
  .site-footer .footer-text {
    max-width: 200px;
    margin: 0 auto;
  }
  .site-footer .bottom-text {
    min-width: inherit;
  }
}

@media (max-width: 400px) {
  .site-footer .wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
}