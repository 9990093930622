@font-face {
  font-family: "Object Sans";
  src: url("./fonts/ObjectSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Medium-WebXL.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Semibold-WebXL.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/dmsans-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: local("Pacifico Regular"), local("Pacifico-Regular"), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format("woff2");
  font-display: swap;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #7c7c7c;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

*, *::before, *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

img {
  width: 100%;
  pointer-events: none;
  vertical-align: middle;
  border-style: none;
}

a, button {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: 0px !important;
  -webkit-appearance: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

a[href] {
  cursor: pointer;
}

strong {
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1 {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -4px;
  color: #000000;
}

h2 {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: -3px;
  color: #000000;
}

h3 {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 52px;
  letter-spacing: -2px;
  color: #000000;
}

h4 {
  margin: 0;
  padding: 0;
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: #000000;
}

h5 {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #000000;
}

mark {
  background-color: transparent;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.small-text {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #7c7c7c;
}

.button-icon {
  display: inline-grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 9px;
  align-items: center;
  height: 50px;
  width: max-content;
  padding: 0 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  border-radius: 10px;
  border: 1px solid transparent;
}

.button-icon.purple {
  color: #ffffff;
  background-color: rgba(145, 48, 222, 1);
}

/*layout*/

.container-custom {
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .container-custom {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container-custom {
    max-width: 720px !important;
  }
}

@media (min-width: 991px) {
  .container-custom {
    max-width: 960px !important;
  }
}

@media only screen and (min-width: 1270px) {
  .container-custom {
    max-width: 1200px !important;
  }
}

@media only screen and (min-width: 1510px) {
  .container-custom {
    max-width: 1490px !important;
  }
}

/*ant-design customize*/

/*avatar*/
.ant-avatar {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

/*datapicker*/

.ant-picker {
  border-radius: 10px;
}

.ant-picker .anticon svg {
  fill: #7c7c7c;
}

/*pagination*/

.ant-pagination-item {
  border-radius: 10px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-prev .ant-pagination-item-link:hover, .ant-pagination-prev .ant-pagination-item-link:focus, .ant-pagination-next .ant-pagination-item-link:hover, .ant-pagination-next .ant-pagination-item-link:focus {
  border-color: #000000;
  color: #000000;
}

.ant-pagination-item:focus, .ant-pagination-item:hover, .ant-pagination-item:active, .ant-pagination-item-active, .ant-pagination-item-active:active, .ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
  border-color: #000000;
  color: #000000;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
  background: #F8F8F8;
  border: 1px solid #E7E7E7;
}

.ant-pagination-options-size-changer.ant-select {
  display: none;
}

/*table*/

.ant-table-thead>tr>th {
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}

/*dnd*/

.ant-upload-list-text-container {
  display: none;
}

.show-text-list .ant-upload-list-text-container {
    display: block;
}

.ant-upload.ant-upload-drag {
  border-radius: 10px;
  background: #ffffff !important;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 35px 0;
  border-radius: 10px;
}

.ant-upload-hint {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-table-cell {
  color: rgba(0, 0, 0, 0.7);
}

.ant-select-disabled {
  background: #f5f5f5;
  cursor: not-allowed;
}

/*notification*/

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px;
  margin-right: 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.ant-notification-notice {
  width: 480px;

  padding: 10px 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.ant-notification-notice-close {
  right: 12px;
  top: 11px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  display: none;
}

.ant-form-item .absolute-button {
    position: absolute;
    top: 18px;
    right: 15px;
}

.ant-form-item .dynamic-delete-button {
    color: #ff0202;
}

.ant-form-item .dynamic-delete-button:hover {
    color: #ff5e5e;
}

.ant-picture-wall-uploader {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 7px 10px 2px;
    background-color: #f5f5f5;
}

.ant-picture-wall-uploader .ant-upload.ant-upload-select-picture-card,
.pack-edit .pack-cover__container .ant-picture-wall-uploader .ant-upload.ant-upload-select-picture-card,
.pack-create .pack-cover__container .ant-picture-wall-uploader .ant-upload.ant-upload-select-picture-card {
    width: 100px;
}

.ant-picture-wall-uploader .ant-upload-list-picture-card-container,
.pack-edit .pack-cover__container .ant-picture-wall-uploader .ant-upload-list-picture-card-container,
.pack-create .pack-cover__container .ant-picture-wall-uploader .ant-upload-list-picture-card-container {
    width: 100px;
    position: relative;
    border-radius: 10px;
}

.ant-upload-no-preview .ant-upload-list-item-actions a[title='Preview file'] {
    display: none;
}
