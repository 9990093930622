.tabs {
  display: inline-flex;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 4px;
  flex-wrap: wrap;
  width: 100%;
}

.tabs__item {
  position: relative;
  border-radius: 7px;
  font-size: 16px;
  line-height: 32px;
  padding: 0 15px;
  color: #7c7c7c;
  transition: color 0.15s ease-in, background-color 0.15s ease-in, box-shadow 0.15s ease-in;
  width: 50%;
  text-align: center;
}

.tabs__item::after {
  position: absolute;
  top: 3.5px;
  right: 0;
  display: block;
  content: '';
  width: 1px;
  height: calc(100% - 7px);
  background-color: #e7e7e7;
}

.tabs__item:hover {
  color: #000;
  cursor: pointer;
  background-color: #fafafa;
  box-shadow: 0px 2px 10px rgba(104, 76, 76, 0.1);
}

.tabs__item:nth-child(2n)::after {
  display: none;
}

.tabs__item:last-child::after {
  display: none;
}

.tabs__item.active {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 376px) {
  .tabs {
    width: auto;
  }

  .tabs__item {
    width: auto;
  }

  .tabs__item:nth-child(2n)::after {
    position: absolute;
    top: 3.5px;
    right: 0;
    display: block;
    content: '';
    width: 1px;
    height: calc(100% - 7px);
    background-color: #e7e7e7;
  }

  .tabs__item:last-child::after {
    display: none;
  }
}
