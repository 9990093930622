@font-face {
  font-family: "Object Sans";
  src: url("./fonts/ObjectSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Medium-WebXL.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Semibold-WebXL.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "DM Sans";
  src: url("./fonts/dmsans-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
  url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
  format("woff2");
  font-display: swap;
}

body {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
}
