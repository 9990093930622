  
@media (max-width: 1270px) {
  .table-wrapper {
    max-width: 770px;
    overflow-x: scroll;
  }
}

@media (max-width: 991px) {
    .table-wrapper {
      max-width: 510px;
    }
  }
