.custom-tabs .nav-pills {
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    background: #F2F2F2;
    border-radius: 10px;
}
.custom-tabs .nav-link {
    padding: 0 13px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #7C7C7C;
    border-radius: 7px;
    cursor: pointer;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.custom-tabs .nav-link:hover {
    color: #000000;
}
.custom-tabs .nav-link.active {
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
}
.custom-tabs .badge {
    margin-left: 8px;
}