.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-container__item {
  width: 100%;
  margin: 0 0 30px 0;
}

@media (min-width: 992px) {
  .flex-container__item {
    width: calc(50% - 15px);
    margin: 0 30px 30px 0;
  }

  .flex-container__item:nth-child(2n) {
    margin: 0 0 30px 0;
  }
}

@media (min-width: 1200px) {
  .flex-container__item {
    width: calc(33.33% - 20px);
  }

  .flex-container__item:nth-child(2n) {
    margin: 0 30px 30px 0;
  }

  .flex-container__item:nth-child(3n) {
    margin: 0 0 30px 0;
  }
}
