.plan-create .plan-form {
    margin-top: 30px;
}

.plan-create .plan-form .charge-label {
    font-size: 20px;
    line-height: 30px;
    color: #a9a9a9;
    vertical-align: bottom;
    margin: 50px 20px 0;
}

.plan-create .plan-form label {
    color: #a9a9a9;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
}

.plan-create .plan-form .ant-form-item-required::before {
    display: none;
}

.plan-create .plan-form .ant-input {
    border-color: #cdcdcd;
    border-radius: 10px;
    font-size: 20px;
    line-height: 25px;
    padding: 11px 20px 12px;
}

.plan-create .plan-form .ant-form-item {
    margin-bottom: 30px;
}

.plan-create .plan-form .tagsinput-container {
    margin-bottom: 30px;
}

.plan-create .plan-form .ant-select:not(.ant-select-customize-input) {
    padding: 9px 0 9px;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
}

.plan-create .plan-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    padding: 0 20px;
}

.plan-create .plan-form .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.plan-create .ant-select-dropdown .ant-select-item {
    font-size: 16px;
    line-height: 24px;
}

.plan-create .plan-form .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    outline: none;
}

.plan-create .plan-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
}

.plan-create .plan-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
}

.plan-create .plan-form .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-size: 20px;
    line-height: 30px;
}

.plan-create .plan-form .ant-upload-list-item-actions svg {
    fill: #fff;
}

.plan-create .plan-form .ant-select-selection-overflow-item .ant-select-selection-item {
    background-color: #000;
    color: #fff;
    border-radius: 7px;
}

.plan-create .plan-form .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
}

.plan-create .plan-form .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove svg {
    fill: #fff;
}

.plan-form .ant-btn[type="submit"] {
    height: auto;
    padding: 10px 20px;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    border-radius: 10px;
}

.plan-form .ant-btn[type="submit"]:not(.ant-btn[disabled]) {
    background: #2c3fee;
    color: #fff;
}

.plan-form .ant-btn[type="submit"]:not(.ant-btn[disabled]):hover {
    background: rgba(44, 63, 238, 0.8);
    color: #fff;
}

.plan-create .plan-form .ant-btn-dangerous {
    height: auto;
    padding: 20px 0;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    border-radius: 0;
    background: none !important;
    color: rgba(254, 52, 52, 1);
}

.plan-create .plan-form .ant-btn-dangerous:hover {
    color: rgba(254, 52, 52, 0,8);
}

.plan-create .content-row {
    margin-top: 75px;
}

.plan-create .button-column {
    margin-bottom: 50px;
}

.plan-create .left-column {
    padding-left: 80px;
}

.plan-create .custom-item .ant-form-item-label{
    visibility: hidden;
}

.plan-create input::-webkit-outer-spin-button,
.plan-create input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.plan-create input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 991px) {
    .plan-create .left-column {
        margin-top: 50px;
        padding-left: 15px;
    }
}

@media (max-width: 767px) {
    .plan-create .button-column {
        margin-top: 50px;
    }
    .plan-create .custom-item {
        margin-top: 20px;
    }
    .plan-create .custom-item .ant-form-item-label{
        display: none;
    }
    .plan-create .plan-form .charge-label {
        margin: 0px 0px 50px;
    }
}

@media (max-width: 350px) {
    .plan-create .plan-form .button-wrapper {
        flex-direction: column;
    }
}
