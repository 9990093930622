/*widget-pack*/

.widget-pack {
  display: grid;
  grid-template-columns: 94px auto;
  align-items: center;
  grid-gap: 20px;
  width: max-content;
}

.widget-pack img {
    width: 94px;
    height: 94px;
  }
  
  .widget-pack img {
    position: absolute;
  
    height: inherit;
    border-radius: 50%;
    z-index: 3;
  }
  .widget-pack .image-link {
    position: relative;

    width: 94px;
    height: 94px;
  
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .widget-pack .image-link:after {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/admin/assets/images/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 40px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 2;
  }
  .widget-pack .image-link:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      background: rgba(242, 242, 242, 0.4);
      z-index: -2;
  }
  .widget-pack .image-link:hover {
    opacity: 0.8;
  }
  .widget-pack .text-link:hover .title {
    color: rgba(0, 0, 0, 0.7);
  
  }
  .widget-pack .poster {
    border: 1px solid transparent;
    border-radius: 50%;
  }
  .widget-pack .poster {
    height: 94px;
    border-radius: 50%;
  }

  .widget-pack .description {
    padding-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #7C7C7C;
  }
  .widget-pack .title {
    padding-top: 11px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  
  }
  
  .widget-pack.full .description {
    padding-top: 15px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #A9A9A9;
  }
