.ErrorBoundary {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:rgb(255, 212, 212);
}

.ErrorBoundary__title {
  color: rgb(134, 40, 40);
}

.ErrorBoundary__text {
  margin: 45px 0 30px;
}

.ErrorBoundary a {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: rgb(201, 3, 3);
}

.ErrorBoundary a:hover {
  color: rgb(168, 2, 2);
}
