  .pack-create .pack-form {
    margin-top: 30px;
  }

  .pack-create .pack-form label {
    color: #a9a9a9;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .pack-create .pack-form .ant-form-item-required::before {
    display: none;
  }

  .pack-create .pack-form .ant-input {
    border-color: #cdcdcd;
    border-radius: 10px;
    font-size: 20px;
    line-height: 25px;
    padding: 11px 20px 12px;
  }

  .pack-create .pack-form .ant-form-item {
      margin-bottom: 30px;
  }

  .pack-create .pack-form .ant-form-item.form-list-item {
      margin-bottom: 0;
  }

  .pack-create .pack-form .tagsinput-container {
    margin-bottom: 30px;
  }

  .pack-create .pack-form .ant-select:not(.ant-select-customize-input) {
    padding: 9px 0 9px;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
  }

  .pack-create .pack-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    padding: 0 20px;
  }

  .pack-create .pack-form .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .pack-create .ant-select-dropdown .ant-select-item {
    font-size: 16px;
    line-height: 24px;
  }

  .pack-create .pack-form .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    outline: none;
  }

  .pack-create .pack-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }

  .pack-create .pack-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
  }

  .pack-create .pack-form .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-size: 20px;
    line-height: 30px;
  }

  .pack-create .pack-cover__container {
    position: relative;
  }

  .pack-create .pack-cover__container .ant-upload-list-picture-card-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }

  .pack-create .pack-cover__container .ant-upload-list-picture-card-container .ant-upload-list-item {
    border-radius: 10px;
  }

  .pack-create .pack-cover__container .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
  }

  .pack-create .pack-form .ant-upload-list-item-actions svg {
    fill: #fff;
  }

  .pack-create .pack-form .ant-select-selection-overflow-item .ant-select-selection-item {
    background-color: #000;
    color: #fff;
    border-radius: 7px;
  }

  .pack-create .pack-form .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }

  .pack-create .pack-form .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove svg {
    fill: #fff;
  }

  .pack-create .pack-form-custom .asset-info-grid {
    display: grid;
    grid-template-columns: 125px 1fr auto;
    grid-gap: 15px;
  }

  .pack-create .pack-form-custom .ant-form-item {
    margin-bottom: 15px;
  }

  .pack-create .pack-form .button svg .pack-form-custom .button svg {
    vertical-align: sub;
  }

  .pack-create .pack-form .ant-btn svg path, .pack-form-custom .ant-btn svg path {
    transition: all 0.2s ease-in-out;
  }

  .pack-create .pack-form .ant-btn:hover>svg path, .pack-form-custom .ant-btn:hover>svg path {
    fill: #fe3434;
  }

  .pack-create .pack-form-custom img {
    border: 1px solid #e7e7e7;
  }

  .pack-create .pack-form-custom .ant-input {
    border-color: #cdcdcd;
    border-radius: 10px;
    font-size: 14px;
    line-height: 150%;
    padding: 5px 10px;
  }

  .pack-create .pack-form-custom .image-container {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    min-height: 123px;
  }

  .pack-create .pack-form-custom .image-container:before {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/admin/assets/images/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 40px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .pack-create .pack-form-custom .image-container img {
    grid-row: 1/-1;
    grid-column: 1/-1;
    z-index: 10;
  }

  .pack-create .pack-form-custom .tags {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-row: 2/-1;
    grid-column: 1/-1;
    width: 100%;
    min-height: 40px;
    background: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    z-index: 100;
  }

  .pack-create .pack-form-custom .ant-tag {
    margin: 5px;
    border-radius: 8px;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #7c7c7c;
  }

  .pack-create .pack-form-custom .ant-btn[type="submit"], .pack-form .ant-btn[type="submit"] {
    height: auto;
    padding: 10px 20px;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    border-radius: 10px;
  }

  .pack-create .pack-form-custom .ant-btn[type="submit"]:not(.ant-btn[disabled]), .pack-form .ant-btn[type="submit"]:not(.ant-btn[disabled]) {
    background: #2c3fee;
    color: #fff;
  }

  .pack-create .pack-form-custom .ant-btn[type="submit"]:not(.ant-btn[disabled]):hover, .pack-form .ant-btn[type="submit"]:not(.ant-btn[disabled]):hover {
    background: rgba(44, 63, 238, 0.8);
    color: #fff;
  }

  .pack-create .pack-form .ant-btn-dangerous {
    height: auto;
    padding: 20px 0;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    border-radius: none;
    background: none !important;
    color: rgba(254, 52, 52, 1);
  }

  .pack-create .pack-form .ant-btn-dangerous:hover {
     color: rgba(254, 52, 52, 0,8);
  }

  .pack-create .pack-form-custom .counter {
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    color: #7c7c7c;
  }

  .pack-create .pack-form .cover-card {
    display: grid;
    grid-template-columns: 80px 1fr auto;
    grid-gap: 20px;
    align-items: center;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    padding: 10px;
    height: 104px;
    margin-bottom: 8px;
  }

  .pack-create .pack-form .cover-card .title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
  }

  .pack-create .pack-create .pack-form .cover-card .sizes {
    margin-top: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #A9A9A9;
  }

  .pack-create .pack-form .image-wrapper {
    position: relative;
  }

  .pack-create .pack-form .image-wrapper:before {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/admin/assets/images/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 40px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  .pack-create .pack-form .image-wrapper:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    z-index: 20;
  }

  .pack-create .pack-form .cover-card img {
    border-radius: 50%;
    position: relative;
    z-index: 10;
  }

  .pack-create .pack-form .button-wrapper {
    margin-top: 50px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .pack-create .content-row {
    margin-top: 75px;
  }

  .pack-create .button-column {
    margin-bottom: 50px;
  }

  .pack-create .left-column {
    padding-left: 80px;
  }

  @media (max-width: 991px) {
    .pack-create .left-column {
      margin-top: 50px;
      padding-left: 15px;
    }
  }

  @media (max-width: 768px) {
    .pack-create .button-column {
      margin-top: 50px;
    }
  }

  @media (max-width: 350px) {
    .pack-create .pack-form .button-wrapper {
      flex-direction: column;
    }
  }
